<template>
    <section class="invoice-preview-wrapper">
        <!--
      @paymentChecked="paymentChecked"
      @NotificationCheck="NotificationCheck" -->
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="invoiceData === undefined">
            <h4 class="alert-heading">Error fetching invoice data</h4>
            <div class="alert-body">
                No invoice found with this invoice id. Check
                <b-link class="alert-link" to="/">
                    <!-- :to="{ name: 'apps-invoice-list'}" -->
                    Invoice List
                </b-link>
                for other invoices.
            </div>
        </b-alert>

        <b-row v-if="invoiceData" class="invoice-preview">
            <!-- Col: Left (Invoice Container) -->
            <b-col cols="12" xl="9" md="8">
                <b-card no-body class="invoice-preview-card">
                    <!-- Header -->
                    <b-card-body class="invoice-padding pb-0">
                        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                            <!-- Header: Left Content -->
                            <div>
                                <div class="logo-wrapper">
                                    <img :src="appLogoImage" width="45%" height="100%" alt="" />
                                </div>
                                <p class="card-text">
                                    {{ invoiceData.appointments.facilities.adress }}
                                </p>
                                <div v-if="invoiceData.appointments.facilities">
                                    <p class="card-text">
                                        {{ invoiceData.appointments.facilities.cities.name }},
                                        {{ invoiceData.appointments.facilities.cities.states.name }}
                                    </p>
                                    <p class="card-text mb-0">
                                        {{ invoiceData.appointments.facilities.phone }}
                                    </p>
                                </div>
                            </div>

                            <!-- Header: Right Content -->
                            <div class="mt-md-0 mt-2">
                                <div class="my-1 text-right">
                                    <GoBack class="" />
                                </div>
                                <h4 class="invoice-title">
                                    Invoice
                                    <span class="invoice-number">#{{ invoiceData.id }}</span>
                                </h4>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">Date Issued:</p>
                                    <p class="invoice-date">
                                        {{ dateIssued }}
                                    </p>
                                </div>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">Clinic</p>
                                    <p class="invoice-date">
                                        {{ invoiceData.appointments.facilities.name }}
                                    </p>
                                </div>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">OMA</p>
                                    <p class="invoice-date">
                                        {{ invoiceData.omas.name }} {{ invoiceData.omas.lastname }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </b-card-body>

                    <!-- Invoice Client & Payment Details -->
                    <b-card-body v-if="invoiceData.appointments.patients" class="invoice-padding pt-0">
                        <b-row class="invoice-spacing">
                            <!-- Col: Invoice To -->
                            <b-col cols="12" xl="6" class="p-0" v-if="invoiceData.appointments.patients">
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">Invoice To:</p>
                                    <h6 class="invoice-date">
                                        {{ invoiceData.appointments.patients.name }}
                                        {{ invoiceData.appointments.patients.lastname }}
                                    </h6>
                                </div>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">Phone:</p>
                                    <h6 class="">
                                        {{ invoiceData.appointments.patients.phone }}
                                    </h6>
                                </div>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">DOB:</p>
                                    <h6 class="">
                                        {{ invoiceData.appointments.patients.date_birth }}
                                    </h6>
                                </div>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">Room:</p>
                                    <h6 class="">
                                        {{ invoiceData.room }}
                                    </h6>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>

                    <!-- Invoice Description: Table -->
                    <div v-if="invoiceLaboratory.length > 0">
                        <TitleTable titleTable="Laboratories" iconTable="GitBranchIcon" />
                        <b-table-lite ref="tableRef" selectable select-mode="multi" responsive
                            :items="invoiceLaboratory" :fields="fields">
                            <template #cell(name)="data">
                                <b-card-text class="font-weight-bold px-">
                                    {{ data.item.name }}
                                </b-card-text>
                                <b-card-text class="text-nowrap text-lowercase">
                                    {{ data.item.categories ? data.item.categories[0].name : "" }}
                                </b-card-text>
                            </template>
                            <template #cell(price)="data">
                                <b-card-text class="font-weight">
                                    {{ getPrice(data.item.price) }}
                                </b-card-text>
                            </template>
                            <template #cell(action)="data">
                                <b-form-checkbox v-model="data.item.check" @change="
            changeState(
                data.item,
                data.item.categories
                    ? 'laboratories_id'
                    : 'laboratories_pack_id'
            )
            ">
                                </b-form-checkbox>
                            </template>
                        </b-table-lite>
                    </div>

                    <div v-if="invoiceDiagnosticImagings.length > 0">
                        <TitleTable titleTable="Diagnostic Imagings" iconTable="GitBranchIcon" />
                        <b-table-lite responsive bordered :items="invoiceDiagnosticImagings" :fields="fields">
                            <template #cell(name)="data">
                                <b-card-text class="font-weight-bold">
                                    {{ data.item.name }}
                                </b-card-text>
                                <b-card-text class="text-nowrap text-lowercase">
                                    {{ data.item.category_diagnostic_imagings.name }}
                                </b-card-text>
                            </template>
                            <template #cell(price)="data">
                                <b-card-text class="font-weight">
                                    {{ getPrice(data.item.price) }}
                                </b-card-text>
                            </template>
                            <template #cell(action)="data">
                                <b-form-checkbox v-model="data.item.check"
                                    @change="changeState(data.item, 'diagnostic_imagings_id')">
                                </b-form-checkbox>
                            </template>
                        </b-table-lite>
                    </div>

                    <div v-if="invoiceProcedure.length > 0">
                        <TitleTable titleTable="IH Procedures" iconTable="GitBranchIcon" />
                        <b-table-lite bordered responsive :items="invoiceProcedure" :fields="fields">
                            <template #cell(name)="data">
                                <b-card-text class="font-weight-bold">
                                    {{ data.item.name }}
                                </b-card-text>
                            </template>
                            <template #cell(price)="data">
                                <b-card-text class="font-weight">
                                    {{ getPrice(data.item.price) }}
                                </b-card-text>
                            </template>
                            <template #cell(action)="data">
                                <b-form-checkbox v-model="data.item.check"
                                    @change="changeState(data.item, 'procedures_id')">
                                </b-form-checkbox>
                            </template>
                        </b-table-lite>
                    </div>

                    <div v-if="invoiceTest.length > 0">
                        <TitleTable titleTable="IH Test" iconTable="GitBranchIcon" />
                        <b-table-lite bordered responsive :items="invoiceTest" :fields="fields">
                            <template #cell(name)="data">
                                <b-card-text class="font-weight-bold">
                                    {{ data.item.name }}
                                </b-card-text>
                            </template>
                            <template #cell(price)="data">
                                <b-card-text class="font-weight">
                                    {{ getPrice(data.item.price) }}
                                </b-card-text>
                            </template>
                            <template #cell(action)="data">
                                <b-form-checkbox v-model="data.item.check" @change="changeState(data.item, 'tests_id')">
                                </b-form-checkbox>
                            </template>
                        </b-table-lite>
                    </div>

                    <div v-if="invoiceTreatment.length > 0">
                        <TitleTable titleTable="Treatments" iconTable="GitBranchIcon" />
                        <b-table-lite responsive :items="invoiceTreatment" :fields="fieldsTreatments">
                            <template #cell(name)="data">
                                <b-card-text class="font-weight-bold">
                                    {{ data.item.name }}
                                </b-card-text>
                            </template>
                            <template #cell(price)="data">
                                <b-card-text class="font-weight" v-if="!hasPackage">
                                    {{ data.item.price }}
                                </b-card-text>
                            </template>
                            <template #cell(unit)="data">
                                <b-form-input @change="
            changeSize(
                data.item.office_visitable_id,
                treatments[data.item.name]
            )
            " v-model="treatments[data.item.name]">
                                </b-form-input>
                            </template>
                            <template #cell(action)="data">
                                <div class="d-flex">
                                    <b-form-checkbox class="ml-1" v-model="data.item.check"
                                        @change="changeState(data.item, 'treatments_id')">
                                    </b-form-checkbox>
                                </div>
                            </template>
                        </b-table-lite>
                    </div>

                    <div v-if="membership">
                        <TitleTable titleTable="Membership discounts" iconTable="GitBranchIcon" />
                        <b-table-lite responsive :items="membership" :fields="fieldsMembership">
                            <template #cell(type)="data">
                                <b-card-text class="font-weight-bold">
                                    {{ data.item.membership_parameters_detaibles.parameter.name }}
                                </b-card-text>
                            </template>
                            <template #cell(discount)="data">
                                <b-card-text class="font-weight">
                                    {{ data.item.membership_parameters_detaibles.parameter.price }}
                                </b-card-text>
                            </template>
                        </b-table-lite>
                    </div>

                    <!-- Invoice Description: Total -->
                    <b-card-body class="invoice-padding pb-0">
                        <b-row>
                            <b-col cols="12" md="12" class="mt-md-6 d-flex justify-content-between" order="1"
                                order-md="2">
                                <div>
                                    <img :src="invoiceData.signature" alt="" width="50%" />
                                </div>
                                <div class="invoice-total-wrapper">
                                    <div class="invoice-total-item" v-if="invoiceData.appointments.campaign_cupon">
                                        <p class="invoice-total-title">Campaign:</p>
                                        <p class="invoice-total-amount">
                                            {{ invoiceData.appointments.campaign_cupon.campaign.name }}
                                        </p>
                                    </div>
                                    <div class="invoice-total-item" v-if="invoiceData.appointments.campaign_cupon">
                                        <p class="invoice-total-title">Cupon:</p>
                                        <p class="invoice-total-amount">
                                            {{ getCuponValue(invoiceData.appointments.campaign_cupon.campaign) }}
                                        </p>
                                    </div>
                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">Consultation:</p>
                                        <p class="invoice-total-amount">
                                            $ {{ consultPrice.price }}
                                        </p>
                                    </div>
                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">Subtotal:</p>
                                        <p class="invoice-total-amount">
                                            $ {{ invoiceData.appointments.total - consultPrice.price }}
                                        </p>
                                    </div>
                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">Discount:</p>
                                        <p class="invoice-total-amount">
                                            $ {{ invoiceData.appointments.ajustment }}
                                        </p>
                                    </div>
                                    <hr class="my-50" />
                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">Total:</p>
                                        <p class="invoice-total-amount">
                                            $ {{ checkTotal(invoiceData.appointments.total) }}
                                        </p>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>

                    <!-- Spacer -->
                    <hr class="invoice-spacing" />
                </b-card>
            </b-col>

            <!-- Right Col: Card -->
            <b-col cols="12" md="4" xl="3" class="invoice-actions">
                <b-card>
                    <!-- <b-button
            variant="outline-primary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button> -->
                    <b-button v-b-toggle.sidebar-invoice-add-payment variant="success" class="mb-75" block
                        :disabled="blockConfirm" @click="sendPayment(false)">
                        Confirm Procedure
                    </b-button>
                </b-card>
            </b-col>
        </b-row>
        <ToastNotification ref="toast" />
    </section>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTable,
    BTableLite,
    BCardText,
    BButton,
    BFormCheckbox,
    BFormInput,
    BAlert,
    BLink,
    VBToggle,
} from "bootstrap-vue";
import Logo from "@/components/Logo.vue";
import TitleTable from "@/components/TitleTable.vue";
import ToastNotification from "@/components/ToastNotification";
import GoBack from "@/components/ButtonBack.vue";
import Swal from "sweetalert2";
import { getTotal } from "@/mixins/cupon";

import axiosOV from "@/core/services/api/office-visit";
import axiosR from "@/core/services/api/office-visit/recipe";

import NotificationMixin from "@/mixins/NotificationMixin";


export default {
    name: "RecipeInfo",
    directives: {
        "b-toggle": VBToggle,
    },
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BTable,
        BTableLite,
        BCardText,
        BButton,
        BFormCheckbox,
        BFormInput,
        BAlert,
        BLink,

        TitleTable,
        ToastNotification,
        GoBack,
        Logo,
    },
    mounted() {
        this.getRecipe();
    },
    mixins: [NotificationMixin],
    computed: {
        dateIssued() {
            if (this.invoiceData.created_at) {
                return new Date(this.invoiceData.created_at).toLocaleString();
            } else {
                return "";
            }
        },
        appLogoImage() {
            return (
                process.env.BASE_URL + "media/logos/logo-myclinic.png"
            );
        },
        blockConfirm() {
            const labs = this.invoiceLaboratory.filter((lab) => lab.check == false);
            const diagnostics = this.invoiceDiagnosticImagings.filter((lab) => lab.check == false);
            const procedures = this.invoiceProcedure.filter((lab) => lab.check == false);
            const tests = this.invoiceTest.filter((lab) => lab.check == false);
            const treatments = this.invoiceTreatment.filter((lab) => lab.check == false);

            return (labs.length > 0 || diagnostics.length > 0 || procedures.length > 0 || tests.length > 0 || treatments.length > 0);
        }
    },
    data() {
        return {
            invoiceData: null,
            hasPackage: false,
            paymentDetails: {},
            invoiceLaboratory: [],
            invoiceDiagnosticImagings: [],
            invoiceProcedure: [],
            invoiceTest: [],
            invoiceTreatment: [],
            fields: [
                {
                    key: "name",
                    sortable: true,
                    thClass: "px-3",
                    class: "th-class-recipe",
                },
                {
                    key: "price",
                    label: "Price",
                    sortable: true,
                    class: "th-class-recipe",
                },
                {
                    key: "action",
                    label: "Action",
                    class: "action-print",
                    class: "th-class-recipe",
                },
            ],
            fieldsTreatments: [
                {
                    key: "name",
                    sortable: true,
                    thClass: "px-3",
                    class: "th-class-recipe-treatment",
                },
                {
                    key: "price",
                    label: "Price",
                    sortable: true,
                    class: ["th-class-recipe-treatment", "text-center"],
                },
                {
                    key: "unit",
                    class: "th-class-recipe-treatment",
                },
                {
                    key: "action",
                    label: "Action",
                    class: "action-print",
                    class: "th-class-recipe-treatment",
                },
            ],
            fieldsMembership: [
                {
                    key: "type",
                    sortable: true,
                    // thClass: "px-3",
                    // class: "th-class-recipe-treatment",
                },
                {
                    key: "discount",
                    sortable: true,
                    // class: ["th-class-recipe-treatment", "text-center"],
                },
            ],
            treatments: [],
            treatmentSize: [],
            fieldsVital: [
                { key: "unit", sortable: true, thClass: "px-3" },
                { key: "value", sortable: true },
            ],
            consultPrice: null,
            subtotal: 0,
            membership: []
        };
    },
    methods: {
        getPrice(price) {
            if (this.hasPackage) return "price in package"
            if (price) return price
            return "not assigned"
        },
        checkTotal(total) {
            if (total) return total
            axiosR.adjustTotal({}, this.$route.params.id).then((res) => {
                window.location.reload()
            })
        },
        getRecipe() {
            axiosOV
                .officeVisitDetailt(this.$route.params.id)
                .then(({ office_visit }) => {
                    const {
                        appointments,
                        laboratories,
                        laboratories_pack,
                        diagnostic_imagings,
                        procedures,
                        tests,
                        treatments,
                        package_id,
                    } = office_visit;
                    this.hasPackage = package_id != null ? true : false
                    this.invoiceData = office_visit;
                    const laboratoriesAll = laboratories.concat(laboratories_pack);
                    this.invoiceLaboratory = laboratoriesAll.map((item) => {
                        this.subtotal += parseInt(item.price);
                        // return { ...item, check: item.pivot.status };
                        return { ...item, check: false };
                    });
                    this.invoiceDiagnosticImagings = diagnostic_imagings.map((item) => {
                        this.subtotal += parseInt(item.price);
                        // return { ...item, check: item.pivot.status };
                        return { ...item, check: false };
                    });
                    this.invoiceProcedure = procedures.map((item) => {
                        this.subtotal += parseInt(item.price);
                        // return { ...item, check: item.pivot.status };
                        return { ...item, check: false };
                    });
                    this.invoiceTest = tests.map((item) => {
                        this.subtotal += parseInt(item.price);
                        // return { ...item, check: item.pivot.status };
                        return { ...item, check: false };
                    });
                    this.invoiceTreatment = treatments.map((item) => {
                        this.subtotal += parseInt(item.price * item.pivot.cant);
                        this.treatments[item.name] = item.pivot.cant;
                        // return { ...item, check: item.pivot.status };
                        return { ...item, check: false };
                    });
                    const { consult_prices, membership } = appointments;
                    this.consultPrice = consult_prices;
                    this.membership = membership ? membership.membership_details : [];
                    this.subtotal += consult_prices.price;
                });
        },
        printInvoice() {
            window.print();
        },
        changeState(item, category) {
            const data = {
                office_visits_id: this.invoiceData.id,
                [category]: item.id || item.office_visitable_id,
                status: item.check,
            };
            axiosR.checkProcedure(data).then((res) => { });
        },
        changeSize(id, cant) {
            this.treatmentSize.push({
                id,
                cant: parseInt(cant),
            });
        },
        printDownload() {
            let w = window.print();
            console.log(w);
        },
        sendPayment(statusInvoice = false) {
            const confirm = statusInvoice;
            let data = {};
            if (this.treatmentSize.length > 0) {
                data = {
                    confirm,
                    treatments: this.treatmentSize,
                };
            } else {
                data = {
                    confirm,
                };
            }
            const labs = this.invoiceLaboratory.filter((lab) => lab.check == false);
            const diagnostics = this.invoiceDiagnosticImagings.filter((lab) => lab.check == false);
            const procedures = this.invoiceProcedure.filter((lab) => lab.check == false);
            const tests = this.invoiceTest.filter((lab) => lab.check == false);
            const treatments = this.invoiceTreatment.filter((lab) => lab.check == false);
            if (labs.length > 0 || diagnostics.length > 0 || procedures.length > 0 || tests.length > 0 || treatments.length > 0) {
                Swal.fire({
                    title: '<span class="font-weight-bolder">ATENTION!</span>',
                    html:
                        '<span class="font-weight-bolder">Remember to inform the Oma that some invoice procedures were not performed.</span>',
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Confirm",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        this.sendToBackend(data)
                    }
                });
            } else {
                this.sendToBackend(data)
            }
        },
        getCuponValue(campaign) {
            return campaign.type == "percentage" ? `${campaign.value} %` : `$ ${campaign.value}`
        },
        sendToBackend(data) {
            axiosOV.procedureCheck(this.$route.params.id, data).then(({ registro, ...res }) => {
                if (registro) {
                    this.sendPayment(true);
                } else {
                    const {
                        type,
                        message,
                    } = NotificationMixin.methods.notificationCheck(res);
                    Swal.fire({
                        text: message,
                        icon: type,
                        confirmButtonClass: "btn btn-secondary"
                    })
                    this.$refs.toast[type](message);
                    this.$router.go(-1);
                }
            });
        }
    },
};
</script>

<style lang="scss">
@import "@/assets/sass/pages/invoice/invoice-6.scss";

.th-class-recipe {
    width: 33%;
}

.th-class-recipe-treatment {
    width: 25%;
}
</style>

<style lang="scss">
@media print {

    // Global Styles
    body {
        background-color: transparent !important;
    }

    nav.header-navbar {
        display: none;
    }

    .main-menu {
        display: none;
    }

    .header-navbar-shadow {
        display: none !important;
    }

    .content.app-content {
        margin-left: 0;
        padding-top: 2rem !important;
    }

    .card {
        background-color: transparent;
        box-shadow: none;
    }

    .customizer-toggle {
        display: none !important;
    }

    .action-print {
        display: none !important;
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {
        .row.invoice-preview {
            .col-md-8 {
                max-width: 100%;
                flex-grow: 1;
            }

            .invoice-preview-card {
                .card-body:nth-of-type(2) {
                    .row {
                        >.col-12 {
                            max-width: 50% !important;
                        }

                        .col-12:nth-child(2) {
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-end;
                            margin-top: 0 !important;
                        }
                    }
                }
            }
        }

        // Action Right Col
        .invoice-actions {
            display: none;
        }
    }
}
</style>
